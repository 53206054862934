import { FC } from "react";
import { Navigate, useParams } from "react-router-dom";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import NestedDetailHeaderLayout from "@/mui/components/layout/NestedDetailHeaderLayout";
import NestedDetailLayout from "@/mui/components/layout/NestedDetailLayout";
import SectionBody from "@/mui/components/form/Section/SectionBody";
import SectionHeader from "@/mui/components/form/Section/SectionHeader";
import MuiKendoButton from "@/mui/components/buttons/MuiKendoButton";
import BackLink from "@/mui/components/buttons/BackLink";
import { DCS_BLUE } from "@/mui/theme/colors";
// kendo
import { DropdownInput } from "@/components/inputs/dropdown/DropdownInput";
import { TextInput } from "@/components/inputs/text/TextInput";
import { Checkbox } from "@/components/checkbox/Checkbox";
// state
import { useSettingsViewCtx } from "../../AuthSettingsViewProvider";
import UserAuthDetailProvider, { useUserAuthDetailCtx } from "./UserAuthDetailProvider";
// utils
import { userAuthService } from "@/services/userAuthService";
import { FunctionLink } from "@/components/functionLink/FunctionLink";
import { getRouteParamNum } from "@/utils/routing/formatting";
// interfaces
import { UserAuthRes } from "../../interfaces/user";
import _ from "lodash";
import { UpdateUserAuthReq, UserAuthForm } from "./form";

const Title: FC = () => {
  const userRecIdParam = useParams().userRecId;
  const isNew = userRecIdParam === "new";

  const activeUser = useSettingsViewCtx((s) => s.activeUser);

  const secondary = isNew ? "Add New User" : activeUser?.fullName;

  return (
    <Grid container direction="row" gap={1} wrap="nowrap" alignItems="center">
      <Grid>User Management</Grid>
      <Grid>-</Grid>
      <Grid color={DCS_BLUE} textOverflow="ellipsis">
        {secondary}
      </Grid>
    </Grid>
  );
};

/** ###  */
const UserAuthDetailLayout: FC = () => {
  const userRecIdParam = useParams().userRecId;
  const userRecId = getRouteParamNum(userRecIdParam);
  const isNew = userRecIdParam === "new";

  const users = useSettingsViewCtx((s) => s.users.value ?? []);
  const setUsers = useSettingsViewCtx((s) => s.users.setValue);
  const activeUser = useSettingsViewCtx((s) => s.activeUser);

  const roleRows = useSettingsViewCtx((s) => s.roleRows);
  const companies = useSettingsViewCtx((s) => s.companies.value ?? []);

  // New state
  const formState = useUserAuthDetailCtx((s) => s.formState);
  const { form, setField, getFieldProps, reqBody } = formState;

  const handleSubmit = async () => {
    if (!userRecId) return;

    try {
      await userAuthService.updateUserAuth(reqBody as UpdateUserAuthReq);

      const filteredUsersRes = users.filter((user) => user.recId !== userRecId);

      // @note this form only submits if there is an active user
      const newUser = UserAuthRes.fromForm(activeUser!, form as UserAuthForm);

      const updatedUsersRes = [newUser, ...filteredUsersRes];
      setUsers(updatedUsersRes);
    } catch {}
  };

  if (userRecId === null && userRecIdParam !== "new") {
    return <Navigate to=".." relative="path" replace />;
  }

  return (
    <Grid container direction="column" gap={2}>
      <Grid container direction="row">
        <BackLink />
      </Grid>

      <NestedDetailLayout header={<NestedDetailHeaderLayout headerTitle={<Title />} />} p={0}>
        <SectionHeader label="General Information" px={0} />

        <SectionBody
          colProps={{ gap: 2 }}
          px={0}
          leftColElems={[
            <TextInput
              readOnly={!isNew}
              label="User ID/Email"
              value={activeUser?.userId || "-"}
              key={0}
            />,
            <Checkbox label="Active" {...getFieldProps("active")} key={1} />,
            <TextInput
              readOnly={!isNew}
              label="First Name"
              value={activeUser?.firstName || "-"}
              key={2}
            />,
            <TextInput
              readOnly={!isNew}
              label="Last Name"
              value={activeUser?.lastName || "-"}
              key={3}
            />,
          ]}
          rightColElems={[
            <TextInput
              readOnly={!isNew}
              label="Cell Phone"
              value={activeUser?.phoneCell || "-"}
              key={1}
            />,
            // @todo implement properly
            <DropdownInput
              {...getFieldProps("defCompId")}
              onChange={(e) => {
                const appliedValue =
                  typeof e.value === "number" ? e.value : e.value?.recId ? e.value?.recId : null;
                setField("defCompId", appliedValue);
              }}
              value={companies.find((c) => c.recId === form.defCompId) ?? null}
              label="Default Company"
              required
              data={companies}
              textField="shortName"
              dataItemKey="recId"
              key={3}
            />,
            // @todo add action
            <FunctionLink onClick={() => {}} label="Show/Hide User Permissions" key={4} />,
          ]}
        />

        <SectionHeader label="User Role" px={0} />

        <SectionBody
          colProps={{ gap: 2 }}
          px={0}
          leftColElems={[
            // @todo implement properly
            <DropdownInput
              {...getFieldProps("roleRecId")}
              onChange={(e) => {
                const appliedValue =
                  typeof e.value === "number" ? e.value : e.value?.recId ? e.value?.recId : null;
                setField("roleRecId", appliedValue);
              }}
              value={roleRows.find((r) => r.recId === form.roleRecId) ?? null}
              label="Role Name"
              required
              data={roleRows}
              textField="templateName"
              dataItemKey="recId"
              key={1}
            />,
          ]}
        />

        <SectionHeader label="Permissions" px={0} />

        <SectionBody colProps={{ gap: 2 }} px={0} gap={3}>
          <Checkbox label="Account" {...getFieldProps("dms_Accounts")} />
          <Checkbox label="Collections" {...getFieldProps("dms_Collections")} />
          <Checkbox label="Inventory" {...getFieldProps("dms_Inventory")} />
          <Checkbox label="Sales" {...getFieldProps("dms_Sales")} />
        </SectionBody>

        <Grid container justifyContent="end" alignItems="end">
          <MuiKendoButton onClick={handleSubmit}>Submit</MuiKendoButton>
        </Grid>
      </NestedDetailLayout>
    </Grid>
  );
};

const UserAuthDetail: FC = () => (
  <UserAuthDetailProvider>
    <UserAuthDetailLayout />
  </UserAuthDetailProvider>
);
export default UserAuthDetail;
