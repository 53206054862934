import { FC, MouseEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { DCS_BLUE } from "@/mui/theme/colors";
// state
import { useAuthCtx } from "@/AppProviders/AuthProvider";
// utils
import { config } from "@/config";

const fontStyle = { fontSize: "14px", fontWeight: 500 };
const menuStyle = { ...fontStyle }; // may add to this
const optionStyle: SxProps = { ...fontStyle, px: 2, lineHeight: "20px", minWidth: 200 };

const dropdownOptions: { label: string; route: string; disabled?: boolean }[] = [
  { label: "Company Settings", route: "company", disabled: true },
  { label: "User Management", route: "user-management", disabled: false },
  { label: "Ecom Templates", route: "ecom-templates", disabled: true },
  { label: "Account Settings", route: `${config.idsUrl}/Identity/Account/Manage`, disabled: true },
  { label: "DCS Support Control Center", route: "support", disabled: true },
];

/** ###  */
const AuthSettingsSelector: FC = () => {
  const navigate = useNavigate();
  const userInfo = useAuthCtx((s) => s.userInfo);

  const userInitials =
    userInfo === null
      ? "?"
      : (userInfo?.firstName.substring(0, 1) + userInfo?.lastName.substring(0, 1)).toUpperCase();

  // Popper state - see https://mui.com/material-ui/react-menu/
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleButtonClick: MouseEventHandler<HTMLDivElement | HTMLButtonElement> | undefined = (
    e
  ) => setAnchorEl(e.currentTarget);

  const handleClose = () => setAnchorEl(null);
  const handleSelect: (_: { label: string; route: string }) => MouseEventHandler<HTMLLIElement> =
    (opt) => () => {
      // Change route
      navigate(`/settings/${opt.route}`);
      // Close dropdown
      handleClose();
    };

  // Build options
  const dropdownOptionElems = dropdownOptions.map((opt) => (
    <MenuItem onClick={handleSelect(opt)} sx={optionStyle} disabled={opt.disabled} key={opt.route}>
      {opt.label}
    </MenuItem>
  ));

  return (
    <Grid container direction="column">
      <Avatar
        onClick={handleButtonClick}
        component={Button}
        sx={{ bgcolor: DCS_BLUE, minWidth: 50, minHeight: 50, width: 50, height: 50 }}
      >
        {userInitials}
      </Avatar>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={5}
        disableAutoFocusItem
        disableAutoFocus
        disableScrollLock
        slotProps={{ paper: { sx: { mt: 1 } } }}
        MenuListProps={{ "aria-labelledby": "basic-button", sx: menuStyle }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        TransitionComponent={Fade}
      >
        {dropdownOptionElems}
      </Menu>
    </Grid>
  );
};

export default AuthSettingsSelector;
