import { FC } from 'react';
import { Blocker } from 'react-router-dom';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@/mui/components/buttons/MuiKendoButton';
import CommonModal from '@/mui/components/CommonModal';

/** ###  */
const NavBlock: FC<{ blocker: Blocker }> = ({ blocker }) => {
  return (
    <CommonModal open={blocker.state === 'blocked'} containerProps={{ overflow: 'hidden', py: 0 }}>
      <Grid container direction="column" p="50px" gap="50px">
        <Typography variant="h3" fontSize="31px" fontWeight={700} lineHeight="42px">
          Navigation blocked
        </Typography>
        <Grid container direction="column" justifyContent="center" flex={1}>
          <Grid container my="18.72px" flex={1}>
            <Typography variant="h3" fontSize="18.72px" fontWeight={700} lineHeight="28.08px">
              This page may have unsaved data. Do you want to proceed without saving?
            </Typography>
          </Grid>
          <Grid container direction="row" justifyContent="center" gap="50px">
            <Button onClick={blocker.reset}>Cancel</Button>
            <Button
              onClick={blocker.proceed}
              color="warning"
              sx={{
                background: 'rgb(253, 206, 62)',
                '&:hover': { background: 'rgb(255, 221, 114)' },
              }}
            >
              <Typography fontWeight="inherit" fontSize="inherit" color="black">
                Proceed
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default NavBlock;
