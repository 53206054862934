import { FC, PropsWithChildren, createContext, useEffect, useState } from "react";
import { useParams } from "react-router";
// utils
import { scienapticService } from "@/services/scienapticService";
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
import useReq from "@/utils/useReq";
import { getRouteParamNum } from "@/utils/routing/formatting";
import useSubmitValidationErrors from "./useSubmitValidationErrors";
// interfaces
import { AiUnderwritingReportDb } from "./interfaces";

const useCtxState = () => {
  const appRecId = getRouteParamNum(useParams().id);

  // View states
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Data states
  const reportsHistory = useReq(async () => {
    if (!appRecId) return;

    const res = await scienapticService.getAppEvaluations(appRecId);
    const resMapped = res?.map((r) => AiUnderwritingReportDb.new(r)) ?? [];
    // Filter and sort
    const filteredRes = resMapped.filter((res) => res.applicationId !== 0);
    filteredRes.sort((a, b) => b.dateCreated.toDate().getTime() - a.dateCreated.toDate().getTime());
    return filteredRes;
  });

  const scienapticValidation = useReq(async () => {
    if (!appRecId) return;

    return await scienapticService.validateScienapticInputs(appRecId);
  });

  const [selectedAppEvalIdx, setSelectedAppEvalIdx] = useState(0); // use `null`

  // Fetch list of evaluations from API
  useEffect(() => {
    reportsHistory.load();
    scienapticValidation.load();
  }, [appRecId]);

  // Getters
  const doesHistoryExist = reportsHistory.value !== null && reportsHistory.value.length > 0;
  const latestAppEval = doesHistoryExist ? reportsHistory.value![0]! : null;
  const selectedAppEval =
    doesHistoryExist && selectedAppEvalIdx !== null
      ? reportsHistory.value![selectedAppEvalIdx]
      : null;

  // Getters
  const submitEvalValidationErrors = useSubmitValidationErrors();

  return {
    // View states
    isModalOpen,
    setIsModalOpen,

    // Data states
    selectedAppEvalIdx,
    setSelectedAppEvalIdx,

    // Utils
    reportsHistory,
    scienapticValidation,

    // Getters
    latestAppEval,
    selectedAppEval,
    submitEvalValidationErrors,
  };
};
export type IAiUnderwritingCtx = ReturnType<typeof useCtxState>;
const Ctx = createContext<IAiUnderwritingCtx | null>(null);
export const useAiUnderwritingCtx = useCtxFactory(Ctx);

const AiUnderwritingProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);
export default AiUnderwritingProvider;
