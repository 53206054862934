import dayjs from "dayjs";
import { SidenoteForm } from "./forms";
import { useSidenoteCtx } from "./SidenoteProvider";

function addMonths(theDate: string | Date, numMonths: number): Date {
  return dayjs(theDate).add(numMonths, "month").toDate();
}

function addDays(begDate: string | Date, numberOfDays: number): Date {
  return dayjs(begDate).add(numberOfDays, "day").toDate();
}

function numDays(bDate: string | Date, eDate: string | Date): number {
  const start = dayjs(bDate).startOf("day");
  const end = dayjs(eDate).startOf("day");
  return end.diff(start, "day");
}

function compareDate(bDate: string | Date, cSign: string, eDate: string | Date): number {
  const start = dayjs(bDate);
  const end = dayjs(eDate);

  switch (cSign) {
    case ">":
      return start.isAfter(end) ? 1 : 0;
    case "<":
      return start.isBefore(end) ? 1 : 0;
    default:
      return 0;
  }
}

function getTermDays(schedule: string): number {
  switch (schedule) {
    case "Weekly":
      return 7;
    case "Bi-Weekly":
      return 14;
    case "Semi-Monthly":
      return 15;
    default:
      return 30;
  }
}

function getDaysInYear(schedule: string): number {
  switch (schedule) {
    case "Weekly":
    case "Bi-Weekly":
      return 364;
    case "Semi-Monthly":
    default:
      return 360;
  }
}

function getOddDays(schedule: string, bDate: string | Date, eDate: string | Date): number {
  let days = numDays(bDate, eDate);
  let units: number;
  let estimatedUnits;
  let newDate;

  switch (schedule) {
    case "Weekly":
      units = Math.floor(days / 7);
      return days - units * 7;

    case "Bi-Weekly":
      units = Math.floor(days / 14);
      return days - units * 14;

    case "Semi-Monthly":
      estimatedUnits = Math.floor(days / 15);
      newDate = addSMDays(eDate, -estimatedUnits);
      while (compareDate(newDate, ">", bDate)) {
        estimatedUnits++;
        newDate = addSMDays(eDate, -estimatedUnits);
      }
      estimatedUnits--;
      newDate = addSMDays(eDate, -estimatedUnits);
      days = numDays(bDate, newDate);
      if (days > 15) {
        estimatedUnits++;
        days = 0;
      }
      return days;

    default:
      estimatedUnits = Math.floor(days / 30);
      newDate = addMonths(eDate, -estimatedUnits);
      while (compareDate(newDate, ">", bDate)) {
        estimatedUnits++;
        newDate = addMonths(eDate, -estimatedUnits);
      }
      estimatedUnits--;
      newDate = addMonths(eDate, -estimatedUnits);
      days = numDays(bDate, newDate);
      if (days > 29) {
        estimatedUnits++;
        days = 0;
      }
      return days;
  }
}

function getOddUnits(schedule: string, bDate: string | Date, eDate: string | Date): number {
  let days = numDays(bDate, eDate);
  let estimatedUnits: number;
  let newDate;

  switch (schedule) {
    case "Weekly":
      return Math.floor((days - 7) / 7);

    case "Bi-Weekly":
      return Math.floor((days - 14) / 14);

    case "Semi-Monthly":
      estimatedUnits = Math.floor(days / 15);
      newDate = addSMDays(eDate, -estimatedUnits);
      while (compareDate(newDate, ">", bDate)) {
        estimatedUnits++;
        newDate = addSMDays(eDate, -estimatedUnits);
      }
      estimatedUnits--;
      newDate = addSMDays(eDate, -estimatedUnits);
      days = numDays(bDate, newDate);
      if (days > 15) {
        estimatedUnits++;
        days = 0;
      }
      return estimatedUnits - 1;

    default:
      estimatedUnits = Math.floor(days / 30);
      newDate = addMonths(eDate, -estimatedUnits);
      while (compareDate(newDate, ">", bDate)) {
        estimatedUnits++;
        newDate = addMonths(eDate, -estimatedUnits);
      }
      estimatedUnits--;
      newDate = addMonths(eDate, -estimatedUnits);
      days = numDays(bDate, newDate);
      if (days > 29) {
        estimatedUnits++;
        days = 0;
      }
      return estimatedUnits - 1;
  }
}

function pmtTerm(principal: number, interestRate: number, payment: number): number {
  if (interestRate === 0) {
    return principal / payment;
  } else {
    return -Math.log(1 - (interestRate * principal) / payment) / Math.log(1 + interestRate);
  }
}

function getFinalDate(begDate: string | Date, sched: string, nnpp: number): Date {
  let endDate: Date;

  switch (sched) {
    case "Weekly":
      return dayjs(begDate).add(nnpp * 7, "day").toDate()

    case "Bi-Weekly":
      return dayjs(begDate).add(nnpp * 14, "day").toDate()

    case "Semi-Monthly":
      const eo = nnpp % 2;
      const theDate = dayjs(begDate);
      let days = 0;

      if (eo > 0) {
        days = theDate.date() < 16 ? 15 : -15;
      }

      if (theDate.date() < 16) {
        return dayjs(addMonths(begDate, Math.floor(nnpp / 2)))
          .add(days, "day")
          .toDate();
      } else {
        return dayjs(addMonths(begDate, Math.floor((nnpp + 1) / 2)))
          .add(days, "day")
          .toDate();
      }
      break;

    default: // Monthly
      return dayjs(begDate).add(nnpp, "month").toDate();
  }
}

function addSMDays(theDate: string | Date, numUnits: number): Date {
  let smDate = dayjs(theDate);
  const smDay = smDate.date();
  let sDate: dayjs.Dayjs;

  if (numUnits % 2 === 0) {
    sDate = smDate;
    numUnits /= 2;
  } else {
    if (smDay > 15) {
      sDate = smDate.subtract(15, "day");
      numUnits = Math.floor(numUnits / 2) + 1;
    } else {
      sDate = smDate.add(15, "day");
      numUnits = Math.floor(numUnits / 2);
    }
  }

  let newDate = sDate.add(numUnits, "month");
  return newDate.toDate();
}

export function planLong(
  xnum: number,
  amt: number,
  dd: string | Date,
  sched: string,
  ptype: string
): string {
  const formatted = dayjs(dd).format("MMM D, YYYY");

  switch (xnum) {
    case 0:
      return "";

    case 1:
      if (sched === "Final") {
        return `Final Payment of ${formatDollar(amt)} Due ${formatted}`;
      } else {
        return `1 Payment of ${formatDollar(amt)} Due ${formatted}`;
      }

    default:
      return `${xnum} Payments of ${formatDollar(amt)} ${sched} Beginning ${formatted}`;
  }
}

function formatDollar(num: number): string {
  const p = num.toFixed(2).split(".");
  return (
    "$" +
    p[0]!
      .split("")
      .reverse()
      .reduce((acc, digit, i) => {
        return digit + (i && i % 3 === 0 ? "," : "") + acc;
      }, "") +
    "." +
    p[1]
  );
}

function displayDate(theDate: string | Date): string {
  return dayjs(theDate).format("MM/DD/YYYY");
}

function calculateNextDueDate(
  nextDueDate: string | Date,
  whatDate9: string | Date,
  schedule: string
): Date {
  const convertedDate = dayjs(whatDate9);

  if (
    dayjs(nextDueDate).isBefore(convertedDate, "day") ||
    dayjs(nextDueDate).isSame(convertedDate, "day")
  ) {
    switch (schedule) {
      case "Weekly":
        return convertedDate.add(7, "day").toDate();

      case "Bi-Weekly":
        return convertedDate.add(14, "day").toDate();

      case "Semi-Monthly":
        if (convertedDate.date() > 15) {
          return convertedDate.add(1, "month").subtract(15, "day").toDate();
        } else {
          return convertedDate.add(15, "day").toDate();
        }

      default:
        return convertedDate.add(1, "month").toDate();
    }
  }

  return dayjs(nextDueDate).toDate(); // return the original date if the condition is not met
}

export const useSNReCalc = (form: SidenoteForm) => {
  var today = dayjs().toDate();
  var saledate = today;
  // var saledate = today;
  var nextDueDate = form.nextDueDate.toDate();
  const addon1 = form.addOn1;
  const addon2 = form.addOn2;
  const addon3 = form.addOn3;
  const addon4 = form.addOn4;
  const addon5 = form.addOn5;
  const addon6 = form.addOn6;
  const addon7 = form.addOn7;
  const addon8 = form.addOn8;
  const addon9 = form.addOn9;
  const addon10 = form.addOn10;

  let cod = Math.max(0, form.snDown); // 0 or input
  const intrate = Math.max(0, form.intRate); // 0 or input

  let amtfin;
  const sched = form.pmtSched;
  const firstpmtdue = calculateNextDueDate(nextDueDate, saledate, sched);

  let pmtamt = Math.max(0, form.pmtAmt); // 0 or input
  let numpmts = Math.max(0, form.numPmts ?? 0); // 0 or value from db

  let final;
  let finalduedate;

  var tsp =
    addon1 + addon2 + addon3 + addon4 + addon5 + addon6 + addon7 + addon8 + addon9 + addon10;
  cod = Math.min(cod, tsp);
  amtfin = Math.max(0, tsp - cod);

  let amountfinanced = amtfin;

  var edate = firstpmtdue;
  var bdate = saledate;
  var odds = getOddDays(sched, bdate, edate);
  var units = getOddUnits(sched, bdate, edate);
  var tdays = getTermDays(sched);
  var Daysinyear = getDaysInYear(sched);
  var perrate = intrate / 100 / (Daysinyear / tdays);
  let Days2First = numDays(edate, bdate);
  var finchg = 0;

  var ii = perrate;
  var ii1 = 1 + ii;

  var irate = intrate / 100;
  let finaldue;

  if (pmtamt <= 0) {
    pmtamt = amtfin;
  }

  if (intrate === 0) {
    numpmts = Math.floor(amountfinanced / pmtamt);
    final = amountfinanced - pmtamt * numpmts;

    if (final === 0) {
      finaldue = getFinalDate(firstpmtdue, sched, numpmts - 1);
    } else {
      finaldue = getFinalDate(firstpmtdue, sched, numpmts);
    }

    final = Math.round(final * 100) / 100;
    finalduedate = finaldue.toLocaleDateString();
    finchg = 0;
  } else {
    if (odds < 0) {
      var FirstBal = amountfinanced * (1 + (Days2First * irate) / 365) - pmtamt;

      numpmts = Math.floor(pmtTerm(FirstBal, ii, pmtamt));

      var iiN = Math.pow(ii1, numpmts);
      final = FirstBal * iiN - (pmtamt / ii) * (iiN - 1);

      finaldue = getFinalDate(firstpmtdue, sched, numpmts + 1);
      numpmts = numpmts + 1;
    } else {
      var uprin = amountfinanced * Math.pow(1 + ii, units);
      var FirstBal = uprin * (1 + (odds * irate) / Daysinyear);
      numpmts = Math.floor(pmtTerm(FirstBal, ii, pmtamt));
      var iiN = Math.pow(ii1, numpmts);
      final =
        FirstBal * iiN -
        (pmtamt / ii) * (iiN - 1) +
        (FirstBal * iiN - (pmtamt / ii) * (iiN - 1)) * ii;
      finaldue = getFinalDate(firstpmtdue, sched, numpmts);
    }

    final = Math.round(final * 100) / 100;
    finalduedate = finaldue.toLocaleDateString();
    finchg = numpmts * pmtamt + final - amountfinanced;
  }

  var plan1 = planLong(numpmts, pmtamt, firstpmtdue, sched, "Regular:");
  if (final > 0) {
    var plan2 = planLong(1, final, finalduedate, "Final", "Final:");
  } else {
    var plan2 = "";
  }
  return {
    numpmts,
    pmtamt,
    finalduedate: dayjs(finalduedate),
    tsp,
    amountfinanced,
    final,
    finchg,
    plan1,
    plan2,
    firstpmtdue,
  };
};
