import { FC, useState } from "react";
import { Button, DropdownInput, TextInput } from "@/components";
import SectionColumn from "@/mui/components/form/Section/SectionColumn";
import { useSidenoteCtx } from "./SidenoteProvider";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import MuiKendoDateField from "@/mui/components/form/MuiKendoDateField";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import TooltipLayout from "./TooltipLayout";

const textStyle = { marginTop: "1px", marginBottom: "1px", height: "38px" };
/** ###  */
const LeftContainer: FC = () => {
  const formState = useSidenoteCtx((s) => s.formState);
  const appliedSidenote = useSidenoteCtx((s) => s.appliedSidenote);
  const vendorsList = useSidenoteCtx((s) => s.vendorsList);
  const isNew = useSidenoteCtx((s) => s.isNew);
  const sidenoteTypeOptions = useSidenoteCtx((s) => s.sidenoteTypeOptions);
  const setSidenoteTypeOptions = useSidenoteCtx((s) => s.setSidenoteTypeOptions);
  const [isAddingNewType, setIsAddingNewType] = useState(false);
  const [newSidenoteType, setNewSidenoteType] = useState("");

  const handleAddNewType = () => {
    const updatedSidenoteOptions = [...sidenoteTypeOptions, newSidenoteType];
    setSidenoteTypeOptions(updatedSidenoteOptions);
    formState.setField("snType", newSidenoteType);

    // Clear the new sidenote type field and close the "add new" option
    setNewSidenoteType("");
    setIsAddingNewType(false);
  };

  return (
    <SectionColumn p={0}>
      <TextInput
        {...formState.getFieldProps("snNumber")}
        label="SN Number"
        style={textStyle}
        required
        readOnly={!isNew}
      />
      <MuiKendoDateField
        {...formState.getFieldProps("snDate")}
        label="Date"
        required
        readOnly
        disabled={!isNew}
      />
      <DropdownInput
        label="Type"
        required
        data={["Add New +", ...sidenoteTypeOptions]}
        onChange={(e) => {
          const selectedValue = e.target.value;
          if (selectedValue === "Add New +") {
            setIsAddingNewType(true);
          } else {
            formState.getFieldProps("snType").onChange(e);
          }
        }}
        value={formState.getFieldProps("snType").value}
        disabled={!isNew}
      />
      {isAddingNewType && (
        <div>
          <TextInput
            label="New Sidenote Type"
            value={newSidenoteType}
            onChange={(e) => setNewSidenoteType(e.target.value)}
            required
          />
          <Grid2 container direction="row" flex={1} justifyContent="flex-end" gap={1}>
            <Button
              label="Add New"
              type="button"
              onClick={handleAddNewType}
              buttonStyle={{ width: "90px", marginTop: "10px" }}
            />
            <Button
              label="Cancel"
              type="button"
              onClick={() => {
                setNewSidenoteType("");
                setIsAddingNewType(false);
              }}
              buttonStyle={{ width: "90px", marginTop: "10px" }}
            />
          </Grid2>
        </div>
      )}
      <TooltipLayout>
        <Grid2>
          <DropdownInput
            {...formState.getFieldProps("vendor")}
            onChange={(e) => {
              const appliedValue =
                typeof e.value === "number" ? e.value : e.value?.recId ? e.value?.recId : null;
              formState.setField("vendor", appliedValue);
            }}
            value={vendorsList.find((c) => c.recId === formState.form.vendor)?.vendorName ?? null}
            label="Vendor"
            required
            data={vendorsList}
            textField="vendorName"
            dataItemKey="recId"
            disabled={!isNew}
          />
        </Grid2>
      </TooltipLayout>
      {appliedSidenote?.addLab1 !== "" && (
        <CurrencyInput
          {...formState.getFieldProps("addOn1")}
          label={appliedSidenote?.addLab1 ?? ""}
          style={textStyle}
          disabled={!isNew}
        />
      )}
      {appliedSidenote?.addLab2 !== "" && (
        <CurrencyInput
          {...formState.getFieldProps("addOn2")}
          label={appliedSidenote?.addLab2 ?? ""}
          style={textStyle}
          disabled={!isNew}
        />
      )}
      {appliedSidenote?.addLab3 !== "" && (
        <CurrencyInput
          {...formState.getFieldProps("addOn3")}
          label={appliedSidenote?.addLab3 ?? ""}
          style={textStyle}
          disabled={!isNew}
        />
      )}
      {appliedSidenote?.addLab4 !== "" && (
        <CurrencyInput
          {...formState.getFieldProps("addOn4")}
          label={appliedSidenote?.addLab4 ?? ""}
          style={textStyle}
          disabled={!isNew}
        />
      )}
      {appliedSidenote?.addLab5 !== "" && (
        <CurrencyInput
          {...formState.getFieldProps("addOn5")}
          label={appliedSidenote?.addLab5 ?? ""}
          style={textStyle}
          disabled={!isNew}
        />
      )}
      {appliedSidenote?.addLab6 !== "" && (
        <CurrencyInput
          {...formState.getFieldProps("addOn6")}
          label={appliedSidenote?.addLab6 ?? ""}
          style={textStyle}
          disabled={!isNew}
        />
      )}
      {appliedSidenote?.addLab7 !== "" && (
        <CurrencyInput
          {...formState.getFieldProps("addOn7")}
          label={appliedSidenote?.addLab7 ?? ""}
          style={textStyle}
          disabled={!isNew}
        />
      )}
      {appliedSidenote?.addLab8 !== "" && (
        <CurrencyInput
          {...formState.getFieldProps("addOn8")}
          label={appliedSidenote?.addLab8 ?? ""}
          style={textStyle}
          disabled={!isNew}
        />
      )}
      {appliedSidenote?.addLab9 !== "" && (
        <CurrencyInput
          {...formState.getFieldProps("addOn9")}
          label={appliedSidenote?.addLab9 ?? ""}
          style={textStyle}
          disabled={!isNew}
        />
      )}
      {appliedSidenote?.addLab10 !== "" && (
        <CurrencyInput
          {...formState.getFieldProps("addOn10")}
          label={appliedSidenote?.addLab10 ?? ""}
          style={textStyle}
          disabled={!isNew}
        />
      )}
    </SectionColumn>
  );
};

export default LeftContainer;
