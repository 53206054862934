import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import { COLOR_INACTIVE } from '@/mui/theme/colors';
// kendo
import { Tooltip } from '@progress/kendo-react-all';
// state
import { useAccountSelector } from '@/features/Accounts/accountSlice';
// interfaces
import { AccountInformation } from '@/interfaces/Accounts';
import { BuyerTypeUnion } from '@/enums/general';
// style
import styles from '../../AccountDetail.module.scss';

// Typesafe object-key lookup
type BuyerTypeKey = keyof Pick<AccountInformation, 'buyer' | 'coBuyer'>;

const buyerTypeLabelMap = new Map<BuyerTypeKey, BuyerTypeUnion>([
  ['buyer', 'Buyer'],
  ['coBuyer', 'Co-Buyer'],
]);
const UserName: FC<{ buyerType: BuyerTypeKey }> = ({ buyerType }) => {
  // We validate that acct-info is not null in parent: `UserInfo`
  const accountInformation = useAccountSelector((s) => s.accountInformation)!;

  const { firstName, lastName } = accountInformation[buyerType];
  const buyerTypeFmt = buyerTypeLabelMap.get(buyerType);

  return (
    <div>
      <div style={{ fontSize: '14px', color: COLOR_INACTIVE }}>{buyerTypeFmt}</div>
      {firstName} {lastName}
    </div>
  );
};

/** ### Style component for items that render in the body of the user-info header */
const BodyItem: FC<{ label: string; value: string | number }> = ({ label, value }) => {
  return (
    <div>
      <span className={styles.bold}>{label}</span> {value}
    </div>
  );
};

const Body: FC<{ buyerType: BuyerTypeKey }> = ({ buyerType }) => {
  // We validate that acct-info is not null in parent: `UserInfo`
  const accountInformation = useAccountSelector((s) => s.accountInformation)!;

  const userInfo = accountInformation[buyerType];
  const buyerTypeFmt = buyerTypeLabelMap.get(buyerType);

  // @note `email` row has unique style
  const infoItems: { label: string; value: string | number }[] = [
    { label: 'Cell:', value: userInfo?.cellPhone },
    { label: 'SSN:', value: userInfo?.ssn ? `***-**-${userInfo.ssn}` : '' },
    { label: 'Address:', value: userInfo?.address },
  ];
  const infoElems = infoItems.map(({ label, value }, idx) => (
    <BodyItem label={label} value={value} key={`bi-${idx}`} />
  ));

  return (
    <div className={styles.userInfo}>
      <Tooltip anchorElement="target" position="bottom">
        <div
          className={userInfo.isEmailBlacklisted ? styles.emailBlacklist : undefined}
          title={
            userInfo.isEmailBlacklisted
              ? userInfo.emailBlacklistReason
                ? `Email Blacklisted: ${userInfo.emailBlacklistReason}`
                : 'Email is blacklisted'
              : undefined
          }
        >
          <BodyItem label="Email:" value={userInfo?.email} />
        </div>
      </Tooltip>
      {infoElems}
    </div>
  );
};

/** ### Layout component for top nav of account-detail - displays user info
 */
const UserInfo: FC<{ buyerType: BuyerTypeKey }> = ({ buyerType }) => {
  const accountInformation = useAccountSelector((s) => s.accountInformation);
  if (accountInformation === null) return <></>;

  /** If "buyer-type" is "co-buyer":
   * - If "co-buyer" is null, don't render
   * - The conditions here were lifted from existing code, but checking on two fields seems unnecessary
   * - Potential bug where appBuyerRecId is set, and linked to buyer even if co-buyer doesn't exist
   */
  if (buyerType === 'coBuyer') {
    const coBuyer = accountInformation[buyerType];
    const hasCoBuyer = coBuyer?.appBuyerRecId && coBuyer?.firstName;
    if (!hasCoBuyer) return <></>;
  }

  return (
    <Grid container direction="row" className={styles.title}>
      <UserName buyerType={buyerType} />
      <Body buyerType={buyerType} />
    </Grid>
  );
};

export default UserInfo;
