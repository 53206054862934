import { PaletteColorOptions, SimplePaletteColorOptions } from "@mui/material/styles/createPalette";
import createTheme, { ThemeOptions } from "@mui/material/styles/createTheme";
// local
import * as colors from "./colors";
import { CustomPalette, CustomOptions } from "./interfaces";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = <T extends string>(
  name: T,
  mainColor: SimplePaletteColorOptions["main"],
  variants?: Omit<SimplePaletteColorOptions, "main">
) =>
  augmentColor({
    name,
    color: {
      main: mainColor,
      light: variants?.light,
      dark: variants?.dark,
      contrastText: variants?.contrastText,
    },
  });

export const customPalette: ThemeOptions["palette"] = {
  red: createColor("red", "rgb(245, 20, 20)"),
  blue: createColor("blue", colors.DCS_BLUE),

  primary: createColor("primary", colors.DCS_BLUE),
  // primary: createColor(colors.DCS_BLUE, { dark: alpha(colors.DCS_BLUE, 0.9) }),
  border: createColor("border", "rgba(0, 0, 0, 0.08)"),
};

declare module "@mui/material/styles" {
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomOptions<CustomPalette, PaletteColorOptions> {}
  interface SimplePaletteColorOptions extends CustomOptions<CustomPalette, PaletteColorOptions> {}
}

declare module "@mui/material/styles/createPalette" {
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomOptions<CustomPalette, PaletteColorOptions> {}
  interface SimplePaletteColorOptions extends CustomOptions<CustomPalette, PaletteColorOptions> {}
}
