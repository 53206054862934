import { FC } from "react";
import { DropdownInput, NumberInput, TextInput } from "@/components";
import SectionColumn from "@/mui/components/form/Section/SectionColumn";
import { useSidenoteCtx } from "./SidenoteProvider";
import MuiKendoDateField from "@/mui/components/form/MuiKendoDateField";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";

const textStyle = { marginTop: "1px", marginBottom: "1px" };

/** ###  */
const RightContainer: FC = () => {
  const formState = useSidenoteCtx((s) => s.formState);
  const isNew = useSidenoteCtx((s) => s.isNew);

  return (
    <SectionColumn p={0} gap={3}>
      <SectionColumn>
        <CurrencyInput
          {...formState.getFieldProps("snTotal")}
          label="Sidenote Total"
          style={textStyle}
          required
          // defaultValue={0}
          disabled
        />
        <CurrencyInput
          {...formState.getFieldProps("snDown")}
          label="Down Payment"
          style={textStyle}
          // defaultValue={0}
          disabled={!isNew}
        />
        <CurrencyInput
          {...formState.getFieldProps("snAmtFin")}
          label="Amount Financed"
          style={textStyle}
          required
          // defaultValue={0}
          disabled
        />

        <NumberInput
          {...formState.getFieldProps("intRate")}
          label="Interest Rate"
          style={textStyle}
          // defaultValue={0} // non Form value
          disabled={!isNew}
        />
        <MuiKendoDateField
          {...formState.getFieldProps("nextDueDate")}
          onChange={(e) => {
            formState.getFieldProps("nextDueDate").onChange(e);
            formState.getFieldProps("pmtDue").onChange(e);
          }}
          label="First Payment Due"
          required
          disabled={!isNew}
        />
        <DropdownInput
          label="Schedule"
          required
          data={["Weekly", "Bi-Weekly", "Semi-Monthly", "Monthly"]}
          onChange={(e) => {
            formState.getFieldProps("pmtSched").onChange(e);
          }}
          value={formState.getFieldProps("pmtSched").value}
          // defaultValue={"Weekly"}
          disabled={!isNew}
        />
        <CurrencyInput
          {...formState.getFieldProps("pmtAmt")}
          label="Payment Amount"
          style={textStyle}
          required
          // defaultValue={0}
          disabled={!isNew}
        />
        <CurrencyInput
          {...formState.getFieldProps("snFinChg")}
          label="Finance Charge"
          style={textStyle}
          required
          // defaultValue={0}
          disabled
        />
        <TextInput
          {...formState.getFieldProps("snDetail")}
          label="SN Detail"
          style={textStyle}
          required
          readOnly={!isNew}
        />
      </SectionColumn>
    </SectionColumn>
  );
};

export default RightContainer;
