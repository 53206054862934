import { FC, PropsWithChildren } from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
// mui
import MuiProvider from "@/mui/MuiProvider";
// components
import Router from "./Router";
// state
import { store } from "@/store/store";
// style
import "@/styles/index.scss";

/** ###  */
const App: FC<PropsWithChildren> = ({ children }) => (
  <Provider store={store}>
    <MuiProvider>{children || <Router />}</MuiProvider>
    <ToastContainer />
    {/* @note add `stacked` prop after upgrading `react-toastify` to v10 - <ToastContainer stacked /> */}
  </Provider>
);

export default App;
