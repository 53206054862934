import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import { GridRenderCellParams } from '@mui/x-data-grid';
import Button from '@/mui/components/buttons/MuiKendoButton';
import LinkButton from '@/mui/components/buttons/LinkButton';
// state
import { useCollectionQueuesCtx } from '../CollectionQueuesProvider';
// utils
import { CollectionQueue } from '@/services/collectionsService';

/** ###  */
const ButtonGroupCell: FC<GridRenderCellParams<CollectionQueue>> = ({ row }) => {
  const setIsModalOpen = useCollectionQueuesCtx((s) => s.setIsModalOpen);
  const setActiveQueue = useCollectionQueuesCtx((s) => s.setActiveQueue);

  // Event handlers
  const handleSelectQueue = () => {
    setIsModalOpen(true);
    setActiveQueue(row);
  };

  return (
    <Grid
      container
      direction="row"
      flex={1}
      gap={1}
      alignContent="center"
      wrap="nowrap"
      fontSize={12}
      fontWeight={500}
    >
      <LinkButton
        to={`./${row.recid}/`}
        color="inherit"
        sx={{ alignSelf: 'center', height: 25, backgroundColor: 'rgb(230, 230, 230)' }}
        style={{ color: 'rgb(17, 24, 39)', fontWeight: 500, fontSize: 12.25 }}
      >
        Run
      </LinkButton>
      <Button
        onClick={handleSelectQueue}
        color="inherit"
        sx={{ alignSelf: 'center', height: 25, backgroundColor: 'rgb(230, 230, 230)' }}
        style={{ color: 'rgb(17, 24, 39)', fontWeight: 500, fontSize: 12.25 }}
      >
        Edit
      </Button>
    </Grid>
  );
};

export default ButtonGroupCell;
