import createTheme from "@mui/material/styles/createTheme";
// local
import { componentDefaults } from "./componentDefaults";
import { kendoMuiTypography } from "../typography";
import { customPalette } from "./palette";

export const lightTheme = createTheme({
  palette: { ...customPalette, mode: "light" },
  typography: kendoMuiTypography,
  components: componentDefaults,
});

// unused
// export const darkTheme = createTheme({
//   ...lightTheme,
//   palette: { ...lightTheme.palette, mode: "dark" },
// });
