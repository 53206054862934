import { FC } from "react";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
// mui
import { DCS_BLUE } from "@/mui/theme/colors";
// components
import PdfDataTable from "@/components/pdfComponents/PdfDataTableView";
import PdfTableSection from "@/components/pdfComponents/PdfDataTableView/PdfTableSection";
import PdfSection from "@/components/pdfComponents/PdfDataTableView/PdfSection";
// utils
import { getLocalDateFormat } from "@/general/dates";
import { getRecommendations } from "../AlertsSection/utils";
import { alertConfigMap } from "../AlertsSection/default";
// interfaces
import { Vehicle } from "@/interfaces/Sales";
import { ApplicationDb } from "@/features/Sales/interfaces/application";
import { AiUnderwritingReportDb } from "../interfaces";
import { IDefaultFormValues } from "@/features/Sales/detailView/WholesalesForm/VehicleTab/default";
import { Alert } from "../AlertsSection/interfaces";
import { PdfColumnInfo } from "@/components/pdfComponents/PdfDataTableView/interfaces";

const { dateDisplayFormatStr } = getLocalDateFormat();

/** ###  */
const ResultsPdfSection: FC<{ currentAppEval: NonNullable<AiUnderwritingReportDb> }> = ({
  currentAppEval,
}) => {
  const finalDecisionColumnLabelMap = new Map<string, PdfColumnInfo>([
    ["applicationId", { label: "App Id" }],
    ["finalDecision", { label: "Decision" }],
    ["finalScore", { label: "Score" }],
    ["finalGrade", { label: "Grade" }],
  ]);
  const buyerResultsColumnLabelMap = new Map<string, PdfColumnInfo>([
    ["applicantScore", { label: "Buyer Score" }],
    ["applicantGrade", { label: "Buyer Grade" }],
  ]);
  const cobuyerResultsColumnLabelMap = new Map<string, PdfColumnInfo>([
    ["coApplicantScore", { label: "Co-buyer Score" }],
    ["coApplicantGrade", { label: "Co-buyer Grade" }],
  ]);

  const sectionHeaderText = `Results: Application ID #${
    currentAppEval.applicationId
  } (${currentAppEval.dateCreated.format(dateDisplayFormatStr)})`;

  return (
    <PdfSection header={sectionHeaderText}>
      <PdfTableSection
        header="Final Decision"
        columnLabelMap={finalDecisionColumnLabelMap}
        rows={[currentAppEval]}
      />
      <PdfTableSection
        header="Buyer Results"
        columnLabelMap={buyerResultsColumnLabelMap}
        rows={[currentAppEval]}
      />
      <PdfTableSection
        header="Co-Buyer Results"
        columnLabelMap={cobuyerResultsColumnLabelMap}
        rows={[currentAppEval]}
      />
    </PdfSection>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  alertCtItemGroup: { justifyContent: "center", columnGap: "4px", padding: "8px" },
  alertCtItemText: { fontSize: "10px" },
  alertMsgGroup: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    rowGap: "4px",
  },
  alertMsgText: { fontSize: "12px", textAlign: "center", padding: "4px" },
});

/** ###  */
const AlertsPdfSection: FC<{ currentAppEval: NonNullable<AiUnderwritingReportDb> }> = ({
  currentAppEval,
}) => {
  const applicantAlerts =
    currentAppEval?.applicantAlerts?.map((a) => new Alert("warning", a)) || [];
  const coApplicantAlerts =
    currentAppEval?.coApplicantAlerts?.map((a) => new Alert("warning", a)) || [];

  const recommendations = getRecommendations(currentAppEval);
  const alerts: Alert[] = [...applicantAlerts, ...coApplicantAlerts, ...recommendations];

  // Count the number of alert types
  const alertsAggSum = { recommendation: 0, error: 0, warning: 0, notice: 0, other: 0 };
  alerts.forEach((a) => alertsAggSum[a.type]++);

  const alertElems: JSX.Element[] = alerts.map((a) => (
    <View
      style={{ ...styles.container, backgroundColor: alertConfigMap[a.type].pdfColor }}
      key={a.message}
    >
      <Text style={{ ...styles.alertMsgText }}>{a.message}</Text>
    </View>
  ));

  return (
    <PdfSection header="Alerts">
      <View style={{ ...styles.container, ...styles.alertCtItemGroup }}>
        <View style={{ ...styles.container, padding: "4px", color: DCS_BLUE }}>
          <Text
            style={styles.alertCtItemText}
          >{`Recommendations: ${alertsAggSum.recommendation}`}</Text>
        </View>
        <View style={{ ...styles.container, padding: "4px", color: "red" }}>
          <Text style={styles.alertCtItemText}>{`Errors: ${alertsAggSum.error}`}</Text>
        </View>
        <View style={{ ...styles.container, padding: "4px", color: "#c7a312" }}>
          <Text style={styles.alertCtItemText}>{`Warnings: ${alertsAggSum.warning}`}</Text>
        </View>
        <View style={{ ...styles.container, padding: "4px", color: "green" }}>
          <Text style={styles.alertCtItemText}>{`Notice: ${alertsAggSum.notice}`}</Text>
        </View>
        <View style={{ ...styles.container, padding: "4px" }}>
          <Text style={styles.alertCtItemText}>{`Other: ${alertsAggSum.other}`}</Text>
        </View>
      </View>
      <View style={{ ...styles.container, ...styles.alertMsgGroup }}>{alertElems}</View>
    </PdfSection>
  );
};

/** ###  */
const DealSummaryPdfSection: FC<{ application: ApplicationDb; vehicle: IDefaultFormValues }> = ({
  application,
  vehicle,
}) => {
  const dealReviewColumnLabelMap = new Map<keyof ApplicationDb, PdfColumnInfo>([
    ["amtFin", { label: "Loan Amount", columnType: "currency" }],
    ["intrate", { label: "Interest Rate", columnType: "percent" }],
    ["cod", { label: "Down Payment", columnType: "currency" }],
    ["term", { label: "Loan Term", columnType: "text" }],
  ]);

  const dealSummaryColumnLabelMap = new Map<keyof ApplicationDb | "ttl", PdfColumnInfo>([
    ["salesPrice", { label: "Lease Price", columnType: "currency" }],
    ["cod", { label: "Due at Lease Signing", columnType: "currency" }],
    ["addon7", { label: "Acquisition Fee", columnType: "currency" }],
    ["sPamt1", { label: "Payment Amount", columnType: "currency" }],
    ["secDeposit", { label: "Security Deposit", columnType: "currency" }],
    ["addon4", { label: "Doc Fee", columnType: "currency" }],
    ["ttl", { label: "T. T. & L.", columnType: "currency" }],
    ["residual", { label: "Residual", columnType: "currency" }],
  ]);
  const ttl = application.title + application.ldowntax + application.registration;

  const vehiclePreviewColumnLabelMap = new Map<keyof Vehicle, PdfColumnInfo>([
    ["vin", { label: "VIN", columnType: "text" }],
    ["make", { label: "Make", columnType: "text" }],
    ["model", { label: "Model", columnType: "text" }],
    ["year", { label: "Year", columnType: "text" }],
  ]);

  return (
    <PdfSection header="Deal Summary">
      <PdfTableSection
        header="Submitted Deal Review"
        columnLabelMap={dealReviewColumnLabelMap}
        rows={[application]}
      />
      <PdfTableSection
        header="Deal Summary"
        columnLabelMap={dealSummaryColumnLabelMap}
        rows={[{ ...application, ttl }]}
      />
      <PdfTableSection
        header="Vehicle Preview"
        columnLabelMap={vehiclePreviewColumnLabelMap}
        rows={[vehicle]}
      />
    </PdfSection>
  );
};

/** ###  */
const AiUnderwritingPdfLayout: FC<{
  currentAppEval: NonNullable<AiUnderwritingReportDb>;
  application: ApplicationDb;
  vehicle: IDefaultFormValues;
}> = ({ currentAppEval, application, vehicle }) => {
  if (!currentAppEval || !application || !vehicle) return <></>;

  return (
    <PdfDataTable>
      <ResultsPdfSection currentAppEval={currentAppEval} />
      <AlertsPdfSection currentAppEval={currentAppEval} />
      <DealSummaryPdfSection application={application} vehicle={vehicle} />
    </PdfDataTable>
  );
};

export default AiUnderwritingPdfLayout;
