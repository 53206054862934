import { FC, useState } from "react";
import { PhoneInput, TextInput, ZipInput } from "@/components";
import DateInput from "@/mui/components/form/MuiKendoDateField";
import Grid from "@mui/material/Unstable_Grid2";
import dayjs from "dayjs";
import { CreditEducationSectionProps } from "@/interfaces/CreditEducation";
import styles from "../CreditEducation.module.scss";
import TitleHeader from "./TitleHeader";

const CustomerInformation: FC<CreditEducationSectionProps> = (props) => {
  const { formData } = props;
  const [showCustomerInformation, setShowCustomerInformation] = useState(true);

  return (
    <Grid container direction="column" flex={1} marginTop={-2}>
      <TitleHeader label="Customer Information" />

      {showCustomerInformation && (
        <Grid container direction="row" flex={1} gap={4} marginY={3}>
          <Grid container direction="column" flex={1} gap={2}>
            <TextInput disabled={true} label="First Name" value={formData?.firstName} />
            <TextInput disabled={true} label="Last Name" value={formData?.lastName} />
            <DateInput
              disabled={true}
              label="Date of Birth"
              value={formData?.dateOfBirth ? dayjs(formData?.dateOfBirth).format("MM/DD/YYYY") : ""}
            />
            <TextInput disabled={true} label="SSN Last 4" value={formData?.SSNLast4 ?? ""} />
            <PhoneInput disabled={true} label="Phone (Cell)" value={formData?.phoneNumber ?? ""} />
          </Grid>

          <Grid container direction="column" flex={1} gap={2}>
            <TextInput disabled={true} label="Email" value={formData?.email ?? ""} />
            <TextInput
              disabled={true}
              label="Current Address"
              value={formData?.currentAddress ?? ""}
            />
            <TextInput disabled={true} label="Current City" value={formData?.currentCity ?? ""} />
            <TextInput disabled={true} label="Current State" value={formData?.currentState ?? ""} />
            <ZipInput disabled={true} label="Current Zip" value={formData?.currentZipCode ?? ""} />
          </Grid>
        </Grid>
      )}

      <a className={styles.anchorText} onClick={() => setShowCustomerInformation((prev) => !prev)}>
        {showCustomerInformation ? "Collapse" : "Expand"} Customer Information
      </a>
    </Grid>
  );
};

export default CustomerInformation;
