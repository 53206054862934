import { useEffect, useState } from "react";
import { z } from "zod";
import { isEqual } from "lodash";
// interfaces
import { Nullish } from "@/interfaces/utilityTypes";
import { FormField, FormFieldProps, FormInput } from "./interfaces";

/**
 * Stateful form with validation, based on `zod`.
 *
 * Optionally handles request body validation.
 */
const useValidatedForm = <
  TFormSchema extends z.ZodObject<TForm>,
  TReqSchema extends z.ZodObject<TReq>,
  //
  TForm extends z.ZodRawShape,
  TReq extends z.ZodRawShape,
  //
  TFormInput extends z.objectInputType<TForm, z.ZodTypeAny, z.UnknownKeysParam> | null
>(
  formSchema: TFormSchema,
  /** @todo set type to `unknown` */
  defaultForm: TFormInput | null,
  reqSchema: TReqSchema,

  /** Define the external data to include in the request-body.
   * The returned 'request-body' object must match the provided request-schema.
   * `(external data) => (form data) => ({ req body })`
   * ```tsx
   * // When defined outside of the component (curried function):
   * const buildReq = (roleRecId: number | null) => (form: RoleForm) => ({ ...form, recId: roleRecId });
   *
   * // When defined inside the hook declaration:
   * const formState = useValidatedForm(
   *   RoleForm,
   *   activeTemplate,
   *   isNew ? AddRoleReq : UpdateRoleReq,
   *   (form) => ({ ...form, recId: roleRecId }) // Normal callback
   * );
   * ```
   * Curried function - form values are provided via the function's inner-scope */
  // buildReq: (form: TFormInput) => Nullish<TReq>
  buildReq: (form: TFormInput) => Nullish<TReqSchema["_output"]>
) => {
  const formSchemaCopy = formSchema.partial();
  const initFormValidated = formSchemaCopy.safeParse(defaultForm ?? {});
  const initForm = initFormValidated.success ? initFormValidated.data : {};
  const [origForm, setOrigForm] = useState<TFormInput>(initForm as TFormInput);
  const [form, setForm] = useState<TFormInput>(initForm as TFormInput);

  // const formSchemaCopy = formSchema.strip() as TFormSchema;
  // const defaultValuesCopy = {};
  // const formFieldKeys = formSchemaCopy.keyof().options as (keyof TForm)[];
  // formFieldKeys.forEach((element) => {
  //   const nullableField = formSchemaCopy.shape[element]!.nullish().default(null);
  //   formSchemaCopy.setKey(element as string, nullableField);

  //   const validatedField = formSchemaCopy.shape[element]!.safeParse(
  //     defaultForm ? defaultForm[element]! : null
  //   );
  //   // @ts-ignore
  //   defaultValuesCopy[element as string] = validatedField.success ? validatedField.data : null;
  // });

  // const validated = formSchemaCopy.safeParse(defaultValuesCopy);
  // const initForm = (validated.success ? validated.data : defaultValuesCopy) as TFormInput;
  // const [origForm, setOrigForm] = useState<TFormInput>(initForm);
  // const [form, setForm] = useState<TFormInput>(initForm);

  const resetDefaults = (newDefaultForm?: TFormInput | null) => {
    const updatedDefaultForm = { ...form, ...newDefaultForm };
    setOrigForm(updatedDefaultForm);
    setForm(updatedDefaultForm);
  };

  useEffect(() => {
    if (initForm === null || !isEqual(origForm, initForm)) {
      resetDefaults(initForm as TFormInput);
    }
  }, [defaultForm]);

  const validation = formSchema.safeParse(form) as z.SafeParseReturnType<
    TFormInput,
    TFormSchema["_output"]
  >;
  const errors = validation.error?.formErrors.fieldErrors as
    | { [P in keyof TFormSchema["_output"]]?: string[] | undefined }
    | undefined;
  const isValid = validation.success;
  const isDirty = !isEqual(origForm, form);

  const reqValidation = reqSchema.safeParse(buildReq(form)) as z.SafeParseReturnType<TReq, TReq>;
  const reqErrors = reqValidation.error?.formErrors.fieldErrors;
  const isReqValid = reqValidation.success;
  const reqBody = reqValidation.data as TReqSchema["_output"];

  const setField = <TKey extends keyof TFormSchema["_output"]>(
    field: TKey,
    value: FormInput<TFormSchema>[TKey] | null
  ) => {
    setForm((origForm) => ({ ...origForm, [field]: value }));
  };

  const getFieldProps = <TKey extends FormField<TFormSchema>>(
    fieldKey: TKey
  ): FormFieldProps<TFormSchema, TKey> => {
    return new FormFieldProps<TFormSchema, TKey>(
      fieldKey,
      setField,
      form as FormInput<TFormSchema>,
      validation as z.SafeParseReturnType<FormInput<TFormSchema>, TFormSchema["_output"]>
    );
  };

  return {
    // Form states
    form,
    setForm,
    setField,
    validation,
    errors,
    isValid,
    isDirty,
    resetDefaults,
    // formFieldProps,
    getFieldProps,

    // Request states
    reqValidation,
    reqBody,
    reqErrors,
    isReqValid,
  } as const;
};

export default useValidatedForm;
