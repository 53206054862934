import { ChangeEvent } from "react";
import { z } from "zod";
import {
  CheckboxChangeEvent,
  NumericTextBoxChangeEvent,
  SwitchChangeEvent,
} from "@progress/kendo-react-inputs";
import { ComboBoxChangeEvent, TimePickerChangeEvent } from "@progress/kendo-react-all";
import dayjs, { isDayjs } from "dayjs";
import { isNull } from "lodash-es";

type OnchangeEventUnion =
  | ComboBoxChangeEvent
  | CheckboxChangeEvent
  | SwitchChangeEvent
  | TimePickerChangeEvent
  | ChangeEvent<HTMLInputElement>
  | NumericTextBoxChangeEvent
  | dayjs.Dayjs | null;

export const OnDeregisterAction = z.enum(["hide", "disable"]).default("hide");
export type OnDeregisterAction = z.infer<typeof OnDeregisterAction>;

export type FormInput<TSchema extends z.ZodObject<z.ZodRawShape>> = z.objectInputType<
  TSchema["shape"],
  TSchema
>;
export type FormField<TSchema extends z.ZodObject<z.ZodRawShape>> = keyof FormInput<TSchema>;
export type FormFieldValue<
  TSchema extends z.ZodObject<z.ZodRawShape>,
  TField extends FormField<TSchema>
> = FormInput<TSchema>[TField];

export class FormFieldProps<
  TFormSchema extends z.ZodObject<z.ZodRawShape>,
  TKey extends FormField<TFormSchema> = FormField<TFormSchema>,
  TValue extends FormFieldValue<TFormSchema, TKey> = FormFieldValue<TFormSchema, TKey>
> {
  value: TValue | null = null;
  errors: string | undefined;
  // shouldDeregister: OnDeregisterAction = 'disable';
  onChange: (e: OnchangeEventUnion) => void;

  constructor(
    field: FormField<TFormSchema>,
    setField: <TKey extends FormField<TFormSchema>>(
      field: TKey,
      value: FormInput<TFormSchema>[TKey] | null
    ) => void,
    form: FormInput<TFormSchema>,
    validation: z.SafeParseReturnType<FormInput<TFormSchema>, TFormSchema["_output"]>
  ) {
    this.onChange = (e: OnchangeEventUnion) => {
      if (isDayjs(e) || isNull(e)) {
        setField(
          field,
          (e ?? null) as FormFieldValue<TFormSchema, FormField<TFormSchema>>
        );
      } else {
        setField(
          field,
          (e.target.value ?? null) as FormFieldValue<TFormSchema, FormField<TFormSchema>>
        );
      }
    };

    this.value = form ? form[field] : null;

    const errors = validation.error?.formErrors.fieldErrors as
      | { [P in keyof FormInput<TFormSchema>]?: string[] | undefined }
      | undefined;
    const errorStr = errors && errors[field]?.join(",\n");

    this.errors = errorStr;
    // this.shouldDeregister = fieldInfo.shouldDeregister;
  }

  // get isRegistered(): boolean {
  //   return true;
  // }
}
