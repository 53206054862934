import { toast } from "react-toastify";
import { AxiosError, isAxiosError } from "axios";
import _ from "lodash";
// utils
import { AxiosService } from "./axiosService";
import { showToastList } from "@/toast/ToastNotiListPersist";
// interfaces
import {
  AiUnderwritingReportDb,
  IScienapticInvalidField,
} from "@/features/Sales/detailView/tabs/ApplicationTab/subviews/AiUnderwritingTab/interfaces";
import { ApiResponse } from "@/interfaces/Api";

class ScienapticService extends AxiosService {
  public constructor() {
    super();
  }

  async generateNewAppEvaluation(appRecId: number) {
    try {
      // @todo remove from params
      const res = await this.axios.post(`/Scienaptic/GenerateAppEvaluation?appRecId=${appRecId}`);
      toast.success("App evaluation generated");
      return res.data.data;
    } catch (e) {
      const err = e as AxiosError<{ ErrorMessages: { [key: string]: string } }>;
      console.error(err);
      if (!isAxiosError(err)) throw err;

      const errMsgs = _.toArray(err.response?.data.ErrorMessages);

      showToastList("Error generating new Scienaptic evaluation", errMsgs, {
        type: "error",
        autoClose: false,
      });

      throw err;
    }
  }

  async validateScienapticInputs(appRecId: number) {
    try {
      // @todo reenable
      // const res = await this.axios.get<ApiResponse<IScienapticInvalidField[]>>(
      //   `/Scienaptic/Validate`,
      //   { params: { appRecId } }
      // );
      return []; // res.data.data;
    } catch (e) {
      toast.error("Error validating Scienaptic app inputs");
      throw e;
    }
  }

  async getAppEvaluations(appRecId: number) {
    try {
      const res = await this.axios.get<ApiResponse<AiUnderwritingReportDb<string, string>[]>>(
        `/Scienaptic/GetAppEvaluations`,
        { params: { appRecId } }
      );

      return res.data.data;
    } catch (error) {
      toast.error("Error fetching Scienaptic evaluations");
      throw error;
    }
  }
}

export const scienapticService = new ScienapticService();
