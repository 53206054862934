import dayjs from "dayjs";
// utils
import { getLocalDateFormat } from "@/general/dates";

/** @deprecated subject to change - as backend gets updated */
export type IScienapticInvalidField = {
  tableName: string;
  recId: number;
  fieldName: string; // field/key
  scienapticFieldName: string;
  message: string;
  pageLocation: string;

  // message, key, other info
};

export class ValidationItem {
  provider?: string;
  key?: string;
  msg?: string;
  label: string;
  value?: unknown;
  /** @deprecated values will not be coming from state anymore */
  statePath?: string;
  pageLocation?: string;

  constructor(item: Omit<ValidationItem, "statePath"> & { rootStatePath?: string }) {
    const statePath = item.rootStatePath ? `${item.rootStatePath}.${item.key ?? ""}` : undefined;

    this.provider = item.provider;
    this.key = item.key;
    this.statePath = statePath;
    this.msg = item.msg;
    this.label = item.label;
    this.value = item.value;
    this.pageLocation = item.pageLocation;
  }

  static fromRes(item: IScienapticInvalidField) {
    return new ValidationItem({
      key: item.fieldName,
      msg: item.message,
      label: item.scienapticFieldName,
      pageLocation: item.pageLocation,
    });
  }
}

export type ScienapticDeserArrFields = ScienapticArrFields<string>;
export type ScienapticSerArrFields = ScienapticArrFields<string[]>;
export class ScienapticArrFields<T extends string | string[]> {
  constructor(
    public finalReviewAlerts: T | null,
    public finalPositiveContributors: T | null,
    public finalNegativeContributors: T | null,
    public finalAdverseActionReasons: T | null,
    public applicantAlerts: T | null,
    public applicantPositiveContributors: T | null,
    public applicantNegativeContributors: T | null,
    public applicantAdverseActionReasons: T | null,
    public coApplicantAlerts: T | null,
    public coApplicantPositiveContributors: T | null,
    public coApplicantNegativeContributors: T | null,
    public coApplicantAdverseActionReasons: T | null
  ) {}

  static new(fields: ScienapticDeserArrFields): ScienapticSerArrFields {
    return new ScienapticArrFields<string[]>(
      JSON.parse(fields.finalReviewAlerts || ""),
      JSON.parse(fields.finalPositiveContributors || ""),
      JSON.parse(fields.finalNegativeContributors || ""),
      JSON.parse(fields.finalAdverseActionReasons || ""),
      JSON.parse(fields.applicantAlerts || ""),
      JSON.parse(fields.applicantPositiveContributors || ""),
      JSON.parse(fields.applicantNegativeContributors || ""),
      JSON.parse(fields.applicantAdverseActionReasons || ""),
      JSON.parse(fields.coApplicantAlerts || ""),
      JSON.parse(fields.coApplicantPositiveContributors || ""),
      JSON.parse(fields.coApplicantNegativeContributors || ""),
      JSON.parse(fields.coApplicantAdverseActionReasons || "")
    );
  }
}

const { tzOffsetHr } = getLocalDateFormat();

/**
 * @note As of 06/10/24, all fields in DB are nullable. This is incorrect and the appropriate fields will be changed in the future.
 * @note As of 06/10/24, several fields are subject to change in type and name.
 * @note As of 06/10/24, some necessary fields are missing in the DB and will be added in the future.
 */
export class AiUnderwritingReportDb<
  T extends string | string[] = string[],
  TDateCreated extends string | dayjs.Dayjs = dayjs.Dayjs
> implements ScienapticArrFields<T>
{
  constructor(
    public createdUtc: dayjs.Dayjs | null,
    public applicationId: number | null,
    public finalDecision: string | null,
    public finalScore: number | null, // @note int
    public finalGrade: string | null,
    public finalReviewAlerts: T | null, // @note this is a string in the database - Scienaptic API returns an array and DCS-API serializes to string before inserting into DB
    public finalPositiveContributors: T | null, // @note this is a string in the database - Scienaptic API returns an array and DCS-API serializes to string before inserting into DB
    public finalNegativeContributors: T | null, // @note this is a string in the database - Scienaptic API returns an array and DCS-API serializes to string before inserting into DB
    public finalAdverseActionReasons: T | null, // @note this is a string in the database - Scienaptic API returns an array and DCS-API serializes to string before inserting into DB

    public recommendedMaxLoanAmount: number | null, // @note int
    public recommendedDownpayment: number | null, // @note int
    public recommendedTermofLoan: number | null, // @note int
    public recommendedInterestRate: number | null, // @note double

    public applicantScore: number | null, // @note int
    public applicantGrade: string | null,
    public applicantAlerts: T | null, // @note this is a string in the database - Scienaptic API returns an array and DCS-API serializes to string before inserting into DB
    public applicantPositiveContributors: T | null, // @note this is a string in the database - Scienaptic API returns an array and DCS-API serializes to string before inserting into DB
    public applicantNegativeContributors: T | null, // @note this is a string in the database - Scienaptic API returns an array and DCS-API serializes to string before inserting into DB
    public applicantAdverseActionReasons: T | null, // @note this is a string in the database - Scienaptic API returns an array and DCS-API serializes to string before inserting into DB

    public coApplicantScore: number | null, // @note int
    public coApplicantGrade: string | null,
    public coApplicantAlerts: T | null, // @note this is a string in the database - Scienaptic API returns an array and DCS-API serializes to string before inserting into DB
    public coApplicantPositiveContributors: T | null, // @note this is a string in the database - Scienaptic API returns an array and DCS-API serializes to string before inserting into DB
    public coApplicantNegativeContributors: T | null, // @note this is a string in the database - Scienaptic API returns an array and DCS-API serializes to string before inserting into DB
    public coApplicantAdverseActionReasons: T | null, // @note this is a string in the database - Scienaptic API returns an array and DCS-API serializes to string before inserting into DB

    public bundledOfferType: string | null,
    public bundledOfferGroup: string | null,
    public bundledOfferGrade: string | null,
    public bundledOfferRecommendedLoanAmount: number | null, // @note int
    public bundledOfferRecommendedInterestRate: number | null, // @note double
    public bundledOfferRecommendedDownpayment: number | null, // @note int
    public bundledOfferRecommendedTermOfLoan: number | null, // @note int

    public dateCreated: TDateCreated
  ) {
    // @todo implement validation
    // Validation for serialized
  }

  static new(
    newAiUnderwritingReport: AiUnderwritingReportDb<string, string>
  ): AiUnderwritingReportDb {
    const deserializedFields = ScienapticArrFields.new(newAiUnderwritingReport);
    const formattedDate = dayjs(newAiUnderwritingReport.createdUtc).subtract(tzOffsetHr, "hour");

    return new AiUnderwritingReportDb(
      newAiUnderwritingReport.createdUtc,
      newAiUnderwritingReport.applicationId,
      newAiUnderwritingReport.finalDecision,
      newAiUnderwritingReport.finalScore,
      newAiUnderwritingReport.finalGrade,
      deserializedFields.finalReviewAlerts,
      deserializedFields.finalPositiveContributors,
      deserializedFields.finalNegativeContributors,
      deserializedFields.finalAdverseActionReasons,
      newAiUnderwritingReport.recommendedMaxLoanAmount,
      newAiUnderwritingReport.recommendedDownpayment,
      newAiUnderwritingReport.recommendedTermofLoan,
      newAiUnderwritingReport.recommendedInterestRate,
      newAiUnderwritingReport.applicantScore,
      newAiUnderwritingReport.applicantGrade,
      deserializedFields.applicantAlerts,
      deserializedFields.applicantPositiveContributors,
      deserializedFields.applicantNegativeContributors,
      deserializedFields.applicantAdverseActionReasons,
      newAiUnderwritingReport.coApplicantScore,
      newAiUnderwritingReport.coApplicantGrade,
      deserializedFields.coApplicantAlerts,
      deserializedFields.coApplicantPositiveContributors,
      deserializedFields.coApplicantNegativeContributors,
      deserializedFields.coApplicantAdverseActionReasons,
      newAiUnderwritingReport.bundledOfferType,
      newAiUnderwritingReport.bundledOfferGroup,
      newAiUnderwritingReport.bundledOfferGrade,
      newAiUnderwritingReport.bundledOfferRecommendedLoanAmount,
      newAiUnderwritingReport.bundledOfferRecommendedInterestRate,
      newAiUnderwritingReport.bundledOfferRecommendedDownpayment,
      newAiUnderwritingReport.bundledOfferRecommendedTermOfLoan,
      formattedDate
    );
  }
}
