import { FC } from "react";
// mui
import { alpha } from "@mui/system/colorManipulator";
import LinkButton from "./LinkButton";
import { DCS_BLUE } from "../../theme/colors";

const sxProps = { background: "unset", "&:hover": { background: alpha(DCS_BLUE, 0.05) } };
const styleProps = { color: DCS_BLUE };

/** ###  */
const BackLink: FC = () => (
  <LinkButton sx={sxProps} style={styleProps} disableElevation to="../">
    {"< Back to list"}
  </LinkButton>
);

export default BackLink;
