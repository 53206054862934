import { FC } from "react";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash-es";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import NestedDetailLayout from "@/mui/components/layout/NestedDetailLayout";
import SectionBody from "@/mui/components/form/Section/SectionBody";
import SectionHeader from "@/mui/components/form/Section/SectionHeader";
import NestedDetailHeaderLayout from "@/mui/components/layout/NestedDetailHeaderLayout";
import Button from '@/mui/components/buttons/MuiKendoButton';
import BackLink from "@/mui/components/buttons/BackLink";
// kendo
import { Checkbox } from "@/components/checkbox/Checkbox";
import { TextInput } from "@/components/inputs/text/TextInput";
// state
import { useSettingsViewCtx } from "../../AuthSettingsViewProvider";
// utils
import { userAuthService } from "@/services/userAuthService";
import { getRouteParamNum } from "@/utils/routing/formatting";
import useValidatedForm from "@/utils/forms/useValidatedForm";
// interfaces
import { AddRoleReq, RoleForm, UpdateRoleReq } from "./interfaces";
import { RoleDb } from "../../interfaces/role";

/** ### Add/Edit "Role Template" */
const AuthRoleDetail: FC = () => {
  const roleRecIdParam = useParams().roleRecId;
  const roleRecId = getRouteParamNum(roleRecIdParam);
  const isNew = roleRecIdParam === "new";

  const activeTemplate = useSettingsViewCtx((s) => s.activeTemplate);
  const roles = useSettingsViewCtx((s) => s.roles.value ?? []);
  const setRoles = useSettingsViewCtx((s) => s.roles.setValue);

  const formState = useValidatedForm(
    RoleForm,
    activeTemplate,
    isNew ? AddRoleReq : UpdateRoleReq,
    (form) => ({ ...form, recId: roleRecId })
  );

  const { isValid, isDirty, reqBody, errors, reqErrors, isReqValid, getFieldProps } = formState;

  const handleSubmit = async () => {
    if (!isEmpty(errors) || !isValid || !isReqValid || !reqBody) {
      console.error("Form contains errors", errors, reqErrors);
      return;
    }

    try {
      // Add type hints
      const appliedReqBody = reqBody as AddRoleReq & UpdateRoleReq;

      if (isNew) {
        const recId = await userAuthService.addRoleTemplate(appliedReqBody);
        if (!recId) {
          console.error("userAuthService.addRoleTemplate(): No recId returned from success req");
          return;
        }

        // Update state
        const updatedRole = RoleDb.fromReqBody({ ...appliedReqBody, recId }, activeTemplate!);
        const updatedRolesRes = [updatedRole, ...roles];
        setRoles(updatedRolesRes);
      } else {
        // @todo need to fix type error for intersection types - `isNew ? AddRoleReq : UpdateRoleReq`
        await userAuthService.updateRoleTemplate(appliedReqBody);

        // Update state
        const filteredRolesRes = roles.filter((role) => role.recId !== appliedReqBody.recId);

        const updatedRole = RoleDb.fromReqBody({ ...appliedReqBody }, activeTemplate!);

        const updatedRolesRes = [updatedRole, ...filteredRolesRes];
        setRoles(updatedRolesRes);
      }
    } catch {}
  };

  const formTitle = isNew ? "Create" : "Edit";

  return (
    <Grid container direction="column" gap={2}>
      <Grid container direction="row">
        <BackLink />
      </Grid>

      <NestedDetailLayout
        header={<NestedDetailHeaderLayout headerTitle={`${formTitle} Role`} />}
        p={0}
      >
        <SectionHeader label="Defined Role" px={0} />

        <SectionBody
          colProps={{ gap: 2 }}
          px={0}
          leftColElems={[
            <TextInput label="Role Name" {...getFieldProps("templateName")} key={1} />,
          ]}
        />

        <SectionHeader label="Permissions" px={0} />

        <SectionBody colProps={{ gap: 2 }} px={0}>
          <Checkbox label="Account" {...getFieldProps("dms_Accounts")} />
          <Checkbox label="Collections" {...getFieldProps("dms_Collections")} />
          <Checkbox label="Inventory" {...getFieldProps("dms_Inventory")} />
          <Checkbox label="Sales" {...getFieldProps("dms_Sales")} />
        </SectionBody>

        <Grid container justifyContent="end" alignItems="end">
          <Button onClick={handleSubmit} disabled={!isDirty || !isValid || !isReqValid}>
            Submit
          </Button>
        </Grid>
      </NestedDetailLayout>
    </Grid>
  );
};

export default AuthRoleDetail;
